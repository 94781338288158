// noinspection GraphQLUnresolvedReference

export const listChairsCatalog = /* GraphQL */ `
    query ListChairsCatalog($categories: [QueryArgument], $search: String, $limit: Int, $offset: Int) {
        chairEntries(categories: $categories, search: $search, limit: $limit, offset: $offset, orderBy: "title ASC") {
            ... on chair_variant_Entry {
                id
                title
                itemNumber
                variantName
                parent {
                    ... on chair_chair_Entry {
                        chairName
                    }
                }
                previewImage {
                    ... on images_Asset {
                        url @transform(width: 200, height: 200, mode: "letterbox")
                        title
                    }
                }
            }
        }
    }
`;

export const getChairVariantUrl = /*GraphQL */ `query GetChairVariantUrl($id: [QueryArgument]) {
    chairEntries(id: $id) {
        ... on chair_variant_Entry {
            variantName
            variantModel {
                url
            }
        }
    }
}
`;

export const getChairVariantInfo = /*GraphQL */ `query GetChairVariantInfo($id: [QueryArgument]) {
    chairEntries(id: $id) {
        ... on chair_variant_Entry {
            variantName
            parent {
                ... on chair_chair_Entry {
                    chairName
                    notes {
                        ... on notes_BlockType {
                            note
                        }
                    }
                }
            }
            itemNumber
            measurements
            fabricLFM
            leatherM2
            variantCost {
                ... on variantCost_BlockType {
                    coverCost
                }
            }
            variantDescription
        }
    }
}
`;

export const getChairConfiguratorInfo = /*GraphQL */ `query GetChairConfiguratorInfo($id: [QueryArgument]) {
    chairEntries(id: $id) {
        ... on chair_variant_Entry {
            variantName
            parent {
                ... on chair_chair_Entry {
                    chairName
                    id
                    accessory {
                        ... on accessory_BlockType {
                            accessoryName {
                                ... on accessories_glider_Entry {
                                    title
                                    articleID
                                }
                            }
                            accessoryCost
                        }
                    }
                }
            }
            variantDescription
            frames {
                ... on frames_BlockType {
                    frame {
                        ... on frame_frame_Entry {
                            id
                            frameName
                            textures {
                                ... on textures_BlockType {
                                    cost
                                    shininess
                                    textureName
                                    texture {
                                        id
                                    }
                                }
                            }
                        }
                    }
                    frameCost
                }
            }
            secondaryFrame {
                ... on secondaryFrame_BlockType {
                    shininess
                    secondaryFrameTexture {
                        id
                    }
                }
            }
        }
    }
}
`;

export const listChairVariants = /*GraphQL */ `
    query ListChairVariants($id: [QueryArgument]) {
        chairEntries(id: $id) {
            ... on chair_chair_Entry {
                id
                children {
                    ... on chair_variant_Entry {
                        id
                        previewImage {
                            url @transform(width: 50, height: 50, mode: "letterbox")
                            title
                        }
                        variantName
                        variantDescription
                    }
                }
                chairName
            }
        }
    }
`;

export const listVariantCovers = /*GraphQL */ `
    query ListVariantCovers($id: [QueryArgument]) {
        chairEntries(id: $id) {
            ... on chair_variant_Entry {
                variantCost {
                    ... on variantCost_BlockType {
                        cover {
                            id
                            ... on covers_cover_Entry {
                                id
                                category
                                title
                            }
                        }
                        coverCost
                    }
                }
            }
        }
    }
`;

export const listVariantCategoryFinishing = /*GraphQL */ `
    query ListVariantFinishing($id: [QueryArgument], $category: [QueryArgument], $search: String) {
        chairEntries(id: $id) {
            ... on chair_variant_Entry {
                variantCost {
                    ... on variantCost_BlockType {
                        cover(category: $category) {
                            id
                            title
                            ... on covers_cover_Entry {
                                id
                                category
                                collection {
                                    ... on colorCatalog_colorCatalog_Entry {
                                        id
                                        colors(search: $search) {
                                            id
                                        }
                                        finishing {
                                            ... on finishing_finishing_Entry {
                                                id
                                                title
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

`;
